import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BannerProps } from "../types/general";

const Banner: React.FC<BannerProps> = ({ imageUrl, text }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: {
          xs: "150px",
          sm: "400px",
          md: "500px",
          lg: "600px",
          xl: "600px",
        }, // Adjusted for responsiveness
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Optional: Adds a dark overlay for better text readability
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{
            color: "#fffdf8",
            fontWeight: "bold",
            textAlign: "center", // Ensure text is centered on all screen sizes
            padding: { xs: "0 20px", sm: "0" }, // Add padding on smaller screens to prevent text from touching the edges
            wordWrap: "break-word",
            fontSize: {
              xs: "2rem", // Smaller font size for extra small screens
              sm: "3rem",
              md: "4rem",
              lg: "5rem",
              xl: "6rem",
            }, // Example of responsive font sizes
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default Banner;
