import React from "react";
import projects from "../data/projects";
import Project from "./Project";
import {
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const ProjectsComp = ({ selectedTag = "all" }) => {
  const theme = useTheme(); // Get the theme object
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Filter projects based on selected tag
  const filteredProjects = selectedTag === "all"
    ? projects
    : projects.filter((project) => project.tag === selectedTag);

  // Sort projects in descending order based on numeric _id
  const sortedProjects = filteredProjects.sort((a, b) => Number(b._id) - Number(a._id));

  return (
    <Grid container spacing={3} className="mt-4 pt-4">
      {sortedProjects.map((project) => (
        <Grid item key={project._id} xs={12} sm={6} md={4}>
          <Project project={project} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectsComp;
