import React from "react";
import { Typography } from "@mui/material";

interface ParagraphTextProps {
  children: React.ReactNode;
}

const ParagraphText: React.FC<ParagraphTextProps> = ({ children }) => {
  return (
    <Typography
      variant="h6"
      gutterBottom
      align="justify"
      sx={{
        lineHeight: "1.8",
        color: "#a4a3a1",
        fontWeight: 400,
        pb: 2,
      }}
    >
      {children}
    </Typography>
  );
};

export default ParagraphText;
