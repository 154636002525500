import defaultImage from "../assets/team/no-user-image.png"; 
import teamImages from '../data/teamImages'

const team = [
  {
    _id: "1",
    name: "Aurel Basuc",
    job: "Founding Partner, Senior Architect, M.Arch",
    image: teamImages.aurel_basuc || defaultImage,
    telefon: "+40 730 617 496",
    email: "aurel@tripticstudio.ro",
  },
  {
    _id: "2",
    name: "Adrian Tutuianu",
    job: "Founding Partner, Senior Architect, M.Arch",
    image: teamImages.adrian_tutuianu || defaultImage,
    telefon: "+40 732 771 959",
    email: "adrian@tripticstudio.ro",
  },
  {
    _id: "3",
    name: "Cristian Danciu",
    job: "Founding Partner, Business Manager, MBA",
    image: teamImages.cristian_danciu || defaultImage,
    telefon: "+40 721 077 733",
    email: "cristian@tripticstudio.ro",
  },
  {
    _id: "4",
    name: "Adriana Popa",
    job: "Senior Architect, M.Arch",
    image: teamImages.adriana_popa || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "5",
    name: "Cezar Mirzan",
    job: "Architect, M.Arch",
    image: teamImages.cezar_mirzan || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "6",
    name: "Valeria Noaghea",
    job: "Senior Architect, M.Arch",
    image: teamImages.valeria_noaghea || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "7",
    name: "Vlad Stoian",
    job: "Senior Architect, M.Arch",
    image: teamImages.vlad_stoian || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "8",
    name: "Veronica Tarcatu",
    job: "Senior Architect, M.Arch",
    image: teamImages.veronica_tarcatu || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "9",
    name: "Liviu Mirica",
    job: "Senior Architect, M.Arch",
    image: teamImages.liviu_mirica || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "10",
    name: "Roberta Teodorescu",
    job: "Junior Architect, M.Arch",
    image: teamImages.roberta_teodorescu || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "11",
    name: "Mihaela Radu",
    job: "Office Manager",
    image: teamImages.mihaela_radu || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "12",
    name: "Andreea Zamfir",
    job: "Junior Architect Intern",
    image: teamImages.andreea_zamfir || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "13",
    name: "Vlad Ion",
    job: "IT Support Specialist",
    image: teamImages.vlad_ion || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "14",
    name: "Marian Basuc",
    job: "Engineer, Fire Specialist, M.Eng",
    image: teamImages.marian_basuc || defaultImage,
    telefon: "",
    email: "",
  },
  {
    _id: "15",
    name: "Florentin Bucur",
    job: "Fire Engineer",
    image: teamImages.florentin_bucur || defaultImage,
    telefon: "",
    email: "",
  },
];

export default team;
