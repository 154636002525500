import images from "../data/projectImages";

const projects = [
 {
   _id: "1",
   name: "City Park Mall Constanta",
   url: "CPM_CT_retail_2016_01",
   image: images.CPM_CT_retail_2016_01[0],
   tag: "private",
   carousel: images.CPM_CT_retail_2016_01.map((img) => ({ imgPath: img })),
   description: "",
   location: "Constanta, Romania",
   client: "NEPI",
   function: "Retail",
   projectPhase: "DTAC+PT",
   status: "Completed",
   programArea: "35,000.00 sqm",
   year: "2016",
 },
 {
   _id: "2",
   name: "AF Apartments",
   url: "Avangarde_rezi_2017_01",
   image: images.Avangarde_rezi_2017_01[0],
   tag: "private",
   carousel: images.Avangarde_rezi_2017_01.map((img) => ({ imgPath: img })),
   description: "",
   location: "Voluntari, Romania",
   client: "Private",
   function: "Residential",
   projectPhase: "Concept",
   status: "Proposal",
   programArea: "4500.00 sqm",
   year: "2017",
 },
 {
   _id: "3",
   name: "WY Logistic Park",
   url: "WY_industrial_2017_02",
   image: images.WY_industrial_2017_02[0],
   tag: "private",
   carousel: images.WY_industrial_2017_02.map((img) => ({ imgPath: img })),
   description: "",
   location: "Bucharest, Romania",
   client: "E-RES Real Estate & Energy",
   function: "Logistic & Industrial",
   projectPhase: "Building Permit; Technical Project",
   status: "Completed",
   programArea: "18,000.00  sqm",
   year: "2018",
 },
 {
   _id: "4",
   name: "Student Housing Bucharest",
   url: "SHB_rezi_2018_01",
   image: images.SHB_rezi_2018_01[0],
   tag: "private",
   carousel: images.SHB_rezi_2018_01.map((img) => ({ imgPath: img })),
   description: "",
   location: "Bucharest, Romania",
   client: "Private",
   function: "Student Housing",
   projectPhase: "Concept",
   status: "Competition – Winning Proposal",
   programArea: "9800.00 sqm",
   year: "2018",
 },
 {
   _id: "5",
   name: "Penny Retail Store",
   url: "Penny_retail_2019_02",
   image: images.Penny_retail_2019_02[0],
   tag: "private",
   carousel: images.Penny_retail_2019_02.map((img) => ({ imgPath: img })),
   description: "",
   location: "Romania",
   client: "Rewe Group",
   function: "Retail Store",
   projectPhase: "Completed",
   status: "Completed",
   programArea: "5000.00 sqm",
   year: "2020",
 },
 {
   _id: "6",
   name: "Elemaster Factory",
   url: "Elemaster_industrial_2019_03",
   image: images.Elemaster_industrial_2019_03[0],
   tag: "private",
   carousel: images.Elemaster_industrial_2019_03.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Arges, Romania",
   client: "Elemaster Group Romania",
   function: "Logistic & Industrial",
   projectPhase: " Building Permit; Technical Project",
   status: "Completed",
   programArea: "10,500.00 sqm",
   year: "2019",
 },
 {
   _id: "7",
   name: "Mamaia Public Square",
   url: "Mamaia_public_2020_01",
   image: images.Mamaia_public_2020_01[0],
   tag: "public",
   carousel: images.Mamaia_public_2020_01.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Constanta, Romania",
   client: "World Bank & Municipality of Constanta County",
   function: "Public",
   projectPhase: "Concept Proposal",
   status: "Proposal",
   programArea: "5000.00 sqm",
   year: "2020",
 },
 {
   _id: "8",
   name: "Constanta Peninsula Promenade",
   url: "Peninsula_public_2020_02",
   image: images.Peninsula_public_2020_02[0],
   tag: "public",
   carousel: images.Peninsula_public_2020_02.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Constanta, Romania",
   client: "World Bank & Municipality of Constanta County",
   function: "Urban Design",
   projectPhase: "",
   status: "Proposal",
   programArea: "200,000.00 sqm",
   year: "2019",
 },
 {
   _id: "9",
   name: "Public Square Mamaia Resort",
   url: "Mamaia_public_2020_03",
   image: images.Mamaia_public_2020_03[0],
   tag: "public",
   carousel: images.Mamaia_public_2020_03.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Mamaia, Romania",
   client: "World Bank & Municipality of Constanta County",
   function: "Urban Design",
   projectPhase: "Concept",
   status: "Proposal",
   programArea: "10,000  sqm",
   year: "2020",
 },
 {
   _id: "10",
   name: "AB House Pallady",
   url: "Bucur_House_rezi_2021_01",
   image: images.Bucur_House_rezi_2021_01[0],
   tag: "private",
   carousel: images.Bucur_House_rezi_2021_01.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Bucharest, Romania",
   client: "Private",
   function: "Residential",
   projectPhase: "Building Permit",
   status: "In Progress",
   programArea: "360 sqm",
   year: "2021",
 },
 {
   _id: "11",
   name: "Maserati Showroom Bucharest",
   url: "Maserati_office_2021_02",
   image: images.Maserati_office_2021_02[0],
   tag: "private",
   carousel: images.Maserati_office_2021_02.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Romania",
   client: "Auto Italia Romania",
   function: "Private",
   projectPhase: "Building Permit; Technical Project",
   status: "Completed",
   programArea: "600.00 sqm",
   year: "2020",
 },
 {
   _id: "12",
   name: "Airport Security Terminal",
   url: "Sibiu_acces_public_2021_03",
   image: images.Sibiu_acces_public_2021_03[0],
   tag: "public",
   carousel: images.Sibiu_acces_public_2021_03.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Sibiu, Romania",
   client: "Private",
   function: "Services",
   projectPhase: "Concept",
   status: "In Progress",
   programArea: "75,000.00  sqm",
   year: "2021",
 },
 {
   _id: "13",
   name: "FIRE DEPARTMENT",
   url: "Sibiu_Remiza_public_2021_04",
   image: images.Sibiu_Remiza_public_2021_04[0],
   tag: "public",
   carousel: images.Sibiu_Remiza_public_2021_04.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Sibiu, Romania",
   client: "Private",
   function: "Services",
   projectPhase: "",
   status: "",
   programArea: "230,000  sqm",
   year: "2021",
 },
 {
   _id: "14",
   name: "153 School Yard Regeneration",
   url: "Curte_scoala_public_2022_01",
   image: images.Curte_scoala_public_2022_01[0],
   tag: "public",
   carousel: images.Curte_scoala_public_2022_01.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Bucharest, Romania",
   client: "World Bank & Municipality of Bucharest - S6",
   function: "Educational",
   projectPhase: "Concept",
   status: "Implemented",
   programArea: "8,000.00 sqm",
   year: "2022",
 },
 {
   _id: "15",
   name: "Harman House Residential",
   url: "Harman_House_rezi_2022_02",
   image: images.Harman_House_rezi_2022_02[0],
   tag: "private",
   carousel: images.Harman_House_rezi_2022_02.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Harman, Brasov, Romania",
   client: "Private",
   function: "Residential",
   projectPhase: "Building Permit; Technical Project",
   status: "In Progress",
   programArea: "450.00 sqm",
   year: "2022",
 },
 {
   _id: "16",
   name: "CRP Strip Mall",
   url: "CRP_retail_2022_03",
   image: images.CRP_retail_2022_03[0],
   tag: "private",
   carousel: images.CRP_retail_2022_03.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Cisnadie, Sibiu, Romania",
   client: "Zacaria Developments",
   function: "Retail",
   projectPhase: "Building Permit; Technical Project",
   status: "Under Construction",
   programArea: "12,000  sqm",
   year: "2024",
 },
 {
   _id: "17",
   name: "Polytechnic Student Housing Bucharest",
   url: "UPB1_public_2023_01",
   image: images.UPB1_public_2023_01[0],
   tag: "public",
   carousel: images.UPB1_public_2023_01.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Bucharest, Romania",
   client: "Polytechnic University of Bucharest",
   function: "Student Housing",
   projectPhase: "Building Permit; Technical Project",
   status: "Completed",
   programArea: "8000.00 sqm",
   year: "2023",
 },
 {
   _id: "18",
   name: "Selimbar Kids Nursery School",
   url: "Cresa_public_2023_02",
   image: images.Cresa_public_2023_02[0],
   tag: "public",
   carousel: images.Cresa_public_2023_02.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Selimbar, Sibiu, Romania",
   client: "Municipality of Selimbar",
   function: "Kids Nursery School",
   projectPhase: "Feasibility Study",
   status: "In Progress",
   programArea: "2500.00  sqm",
   year: "2023",
 },
 {
   _id: "19",
   name: "Selimbar Football Stadium",
   url: "Stadion_Selimbar_public_2023_03",
   image: images.Stadion_Selimbar_public_2023_03[0],
   tag: "private",
   carousel: images.Stadion_Selimbar_public_2023_03.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Vestem, Selimbar, Romania",
   client: "Municipality of Selimbar",
   function: "Sports and Activity",
   projectPhase: "Feasibility Study",
   status: "In Progress",
   programArea: "5000 seats",
   year: "2023",
 },
 {
   _id: "20",
   name: "Brasov Football Stadium",
   url: "Stadion_Bv_public_2023_04",
   image: images.Stadion_Bv_public_2023_04[0],
   tag: "private",
   carousel: images.Stadion_Bv_public_2023_04.map((img) => ({
     imgPath: img,
   })),
   description: "",
   location: "Brasov, Romania",
   client: "Municipality of Brasov",
   function: "Sports and Activity",
   projectPhase: "Feasibility Study",
   status: "In Progress",
   programArea: "10.000 seats",
   year: "2023",
 },
];

export default projects;


