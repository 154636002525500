interface Banner {
  title: string;
  image: string;
}

interface Banners {
  profile: Banner;
  services: Banner;
  employment: Banner;
  people: Banner;
  publications: Banner;
  awards: Banner;
  clients: Banner;
  contact: Banner;
}

const bannersImages = {
  people: require("../assets/banners/team.jpg"),
  profile: require("../assets/banners/profile.jpg")
}

const banners: Banners = {
  profile: {
    title: "Profile",
    image: bannersImages.profile,
  },
  services: {
    title: "Services",
    image:
      "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/4c46a83a-bf79-4790-b824-be70382db1b9/Office2+with+plants.jpg?format=2500w",
  },
  employment: {
    title: "Employment",
    image:
      "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/11ce95a8-238a-4c0b-89f9-75c49022e2c2/02+copy_web_crop.jpg?format=2500w",
  },
  people: {
    title: "People",
    image: bannersImages.people,
  },
  publications: {
    title: "Publications",
    image:
      "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1615462678928-6T5SL1B39EGLLAH4BSFP/CoCreation_1.jpg?format=2500w",
  },
  awards: {
    title: "Awards",
    image:
      "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1509294470414-NC2XBUSNJ2ZO1XHI8G4A/Carlsberg_UCC.jpg?format=2500w",
  },
  clients: {
    title: "Clients",
    image:
      "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/1509294470414-NC2XBUSNJ2ZO1XHI8G4A/Carlsberg_UCC.jpg?format=2500w",
  },
  contact: {
    title: "Contact",
    image:
      "https://images.squarespace-cdn.com/content/v1/51819b9fe4b03000ce6f03ea/4c46a83a-bf79-4790-b824-be70382db1b9/Office2+with+plants.jpg?format=2500w",
  },
};

export default banners;
