const teamImages = {
    aurel_basuc: require("../assets/team/01-aurel-basuc.jpg"),
    adrian_tutuianu: require("../assets/team/02-adrian-tutuianu.jpg"),
    cristian_danciu: require("../assets/team/03-cristian-danciu.jpg"),
    adriana_popa: require("../assets/team/04-adriana-popa.jpg"),
    cezar_mirzan: require("../assets/team/05-cezar-mirzan.jpg"),
    valeria_noaghea: require("../assets/team/06-valeria-noaghea.jpg"),
    vlad_stoian: require("../assets/team/07-vlad-stoian.jpg"),
    veronica_tarcatu: require("../assets/team/08-veronica-tarcatu.jpg"),
    liviu_mirica: require("../assets/team/09-liviu-mirica.jpg"),
    roberta_teodorescu: require("../assets/team/10-roberta-teodorescu.jpg"),
    mihaela_radu: require("../assets/team/11-mihaela-radu.jpg"),
    andreea_zamfir: require("../assets/team/12-andreea-zamfir.jpg"),
    vlad_ion: require("../assets/team/13-vlad-ion.jpg"),
    marian_basuc: require("../assets/team/no-user-image.png"),
    florentin_bucur: require("../assets/team/no-user-image.png"),
};

export default teamImages;
