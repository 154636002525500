import React from "react";
import { Typography } from "@mui/material";

interface SectionTitleProps {
  children: React.ReactNode;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => {
  return (
    <Typography
      variant="h4"
      gutterBottom
      align="center"
      sx={{
        lineHeight: "1.8",
        color: "#434343",
        fontWeight: 900,
        py: 2,
      }}
    >
      {children}
    </Typography>
  );
};

export default SectionTitle;
